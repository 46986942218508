:root {
  scroll-behavior: auto;
}

@font-face {
  font-family: 'Mulish', sans-serif;
  font-display: optional;
  src: url('../src/assets/fonts/Mulish/Mulish-VariableFont_wght.ttf') format('truetype')
}
@font-face {
  font-family: 'Roboto', sans-serif;
  font-display: optional;
  src: url(' https://widget.clutch.co/static/fonts/Roboto-Regular.ttf') format('truetype')
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border-style: none;
}

a {
  text-decoration: none;
}
  
img {
  width: 100%;
  height: auto;
}

ul > li, 
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}
  
h1,
h2,
h3,
p {
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.link {
  text-decoration: none;
}

/* for scrolling to top on navigate in Firefox  */
@-moz-document url-prefix() {
  :root {
    scroll-behavior: auto;
  }
}

/* Cookies styles */
.CookieConsent {
  background-color: #757575!important;
}

/* Carousel styles */
.carousel-indicators {
  margin-bottom: -20px!important;
}

.carousel-indicators button{
  background-color: rgb(161, 161, 161) !important;
  width: 5px !important;
  height: 5px !important;
}

.carousel-control-next {
  right: -20px!important;
}
  
.carousel-control-prev {
  left: -20px!important;
}
  
.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 20px;
  height: 2rem;
} 
  
@media (min-width: 768px) {
  .carousel-control-next {
    right: -40px!important;
  }
    
  .carousel-control-prev {
    left: -40px!important;
  }  
}

@media (min-width: 1200px) {
  .carousel-control-next {
    right: -20px!important;
  }
    
  .carousel-control-prev {
    left: -20px!important;
  }  
}
  
a.carousel-control-next, a.carousel-control-prev {
  width: unset;
}

input#phone   {
  border: none;
  border-color: transparent !important;
}

.PhoneInputCountry {
 padding-left: 3px !important;
}

.PhoneInput {
  padding-left: 10px;
}

span.show-more-less-clickable {
  text-decoration: none !important;
}

.modal-video {
  background-color: rgba(0, 0, 0, 0.7) !important;
  backdrop-filter: blur(2px);
}

.PhoneInput--focus {
  outline-style: solid !important;
  outline-color:#0a7a3b !important;
  outline-width: 1px !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow:0px 3px 10px rgba(0, 0, 0, 0.25);
	-moz-box-shadow:0px 3px 10px rgba(0, 0, 0, 0.25);
}

input.PhoneInputInput {
  border: none !important; 
}

input.PhoneInputInput:focus-visible {
  outline: none !important;
  box-shadow: none;
  background-color: transparent !important;
}
.slick-list {
  overflow: overlay !important;
  overflow-x: hidden !important;
}

@media (min-width: 1600px) {
  .slick-current+div {
    transition: all 0.5s ease-out;
  }

  .slick-current+div {
    transform: translatey(-60px) !important;
    transition: transform 0.2s ease-out;
    transition-delay: 0.5s;
  }

  .slick-track {
    padding-top: 40px;
  }
}